import request from "@/utils/request.js";

/**
 * 根据关键字获取字典
 * @param params
 * @returns {AxiosPromise}
 */
export function getKeyMap(params = []) {
  return request({
    url: "/operateService/api/base/basicDictionary/get",
    method: "post",
    data: { key: params }
  });
}
