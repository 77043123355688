<template>
  <!--  v-if加入原因：如果接口的值返回慢（tree为空），就没有默认值 -->
  <!--    check-strictly-->
  <wlTreeSelect
    v-if="departmentTreeData.length > 0"
    checkbox
    ref="wlTreeSelectRef"
    v-bind="props"
    :collapse-tags="collapse"
    :checkOnClickNode="true"
    :placeholder="placeholder"
    :props="defaultProps"
    :default-checked-keys="defaultChecked"
    :data="departmentTreeData"
    :value="value"
    @change="changeData"
  ></wlTreeSelect>
</template>
<script>
import { getDeptTreeList } from "@/api/organizationalManage/department";

export default {
  props: {
    value: Array,
    // 选中值会以 Tag 的形式展现
    collapse: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "请选择科室"
    },
    props: {
      type: Object,
      default: () => ({})
    },
    defaultDeptFlag: {
      type: Number,
      default: 0
    },
    allDeptFlag: {
      type: Number,
      default: 0
    },
    defaultAllSelected:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "deptName",
        value: "id"
        // disabled: r => r.deptLevel === 1
      },
      departmentTreeData: [],
      defaultChecked: []
    };
  },
  methods: {
    // handleNodeClick(data, checked) {
    //   if (this.isCheckbox) {
    //     return;
    //   }
    //   if (checked === true) {
    //     this.checkedId = data.id;
    //     this.$refs.tree.setCheckedKeys([data.id]);
    //   } else {
    //     if (this.checkedId == data.id) {
    //       this.$refs.tree.setCheckedKeys([data.id]);
    //     }
    //   }
    // },
    changeData(node) {
      this.$emit("input", node);
      this.$emit("change", node);
    },
    RgetNode(r) {
      let n = [{ deptName: r.deptName, id: r.id }];
      if (r.children && Array.isArray(r.children) && r.children.length !== 0) {
        r.children.forEach(a => {
          n.push(...this.RgetNode(a));
        });
      }
      return n;
    }
  },
  mounted() {
    getDeptTreeList({
      // orgId,
      defaultDeptFlag: this.defaultDeptFlag,
      allDeptFlag: this.allDeptFlag
    }).then(res => {
      if (res.length === 0) {
        return;
      }
      this.departmentTreeData = res;
      if(this.defaultAllSelected){
        this.$nextTick(() => {
          let node = this.RgetNode(res[0]);
          this.$emit("input", node);
          this.$emit("change", node);
        });
      }
    });
  },
  created() {
    // let { id: orgId } = this.system.org;
  }
};
</script>
