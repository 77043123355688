import Empty from "@/views/layout/Empty";

export default [
  {
    path: "course",
    name: "Course",
    meta: {
      title: "课程"
    },
    component: Empty,
    redirect: "/course/course_list",
    children: [
      {
        path: "course_list",
        name: "CourseList",
        meta: {
          title: "课程",
          parentName: "Course"
        },
        component: resolve =>
          require(["@/views/course/list/Course.vue"], resolve)
      },
      {
        path: "course_assign",
        name: "CourseAssign",
        alias: "/course_assign",
        meta: {
          title: "课程分配",
          parentName: "CourseList"
        },
        component: resolve =>
          require(["@/views/course/assignCourse/Assign.vue"], resolve)
      },
      {
        path: "course_assign_record",
        name: "CourseAssignRecord",
        alias: "/course_assign_record",
        meta: {
          title: "课程分配记录",
          parentName: "CourseList"
        },
        component: resolve =>
          require(["@/views/course/assignRecord/Record.vue"], resolve)
      },
      {
        path: "course_assign_cancel_record",
        name: "CourseAssignCancelRecord",
        alias: "/course_assign_cancel_record",
        meta: {
          title: "已撤销的记录",
          parentName: "CourseAssignRecord"
        },
        component: resolve =>
          require(["@/views/course/assignCancelRecord/Record.vue"], resolve)
      },
      {
        path: "course_study_progress",
        alias: "/course_study_progress",
        name: "CourseStudyProgress",
        meta: {
          title: "课程学习进度",
          parentName: "CourseList"
        },
        component: resolve =>
          require(["@/views/course/studyProgress/Main.vue"], resolve)
      },
      {
        path: "course_study_progress_details_by_course",
        alias: "/course_study_progress_details_by_course",
        name: "CourseStudyProgressDetailsByCourse",
        meta: {
          title: "课程学习进度详情",
          parentName: "CourseStudyProgress"
        },
        component: resolve =>
          require(["@/views/course/studyProgressDetails/ByCourse.vue"], resolve)
      },
      {
        path: "course_study_progress_details_by_student",
        alias: "/course_study_progress_details_by_student",
        name: "CourseStudyProgressDetailsByStudent",
        meta: {
          title: "课程学习进度详情",
          parentName: "CourseStudyProgress"
        },
        component: resolve =>
          require([
            "@/views/course/studyProgressDetails/ByStudent.vue"
          ], resolve)
      },
      {
        path: "course_convert",
        alias: "/course_convert",
        name: "CourseConvert",
        meta: {
          title: "课程兑换明细",
          parentName: "Course"
        },
        component: resolve =>
          require(["@/views/course/convertRecord/Record.vue"], resolve)
      },
      {
        path: "course_convert_cancel_record",
        alias: "/course_convert_cancel_record",
        name: "CourseConvertCancelRecord",
        meta: {
          title: "已撤销记录",
          parentName: "CourseConvert"
        },
        component: resolve =>
          require(["@/views/course/convertCancelRecord/Record.vue"], resolve)
      }
    ]
  }
];
