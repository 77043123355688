<template>
  <div class="main">
    <div class="sidebar no-print">
      <Sidebar />
    </div>
    <ChildrenMenu class="no-print" />
    <div class="body">
      <BodyMain />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "./Sidebar.vue";
import ChildrenMenu from "./ChildrenMenu.vue";
import BodyMain from "./BodyMain.vue";

export default {
  name: "App",
  data() {
    return {
      userName: ""
    };
  },
  computed: mapState(["system"]),
  methods: {},
  components: { Sidebar, BodyMain, ChildrenMenu }
};
</script>
<style lang="scss">
@import "../../assets/css/variables.scss";

.main {
  width: 100vw;
  height: 100vh;
  display: flex;

  .sidebar {
    width: 90px;
    height: 100%;
    z-index: 3;
    box-shadow: 2px 0px 1px 0px rgba(231, 231, 231, 0.23);
  }

  .body {
    flex: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
