<template>
  <div class="sidebar-content">
    <div class="sidebar-top">
      <el-popover
        placement="right"
        popper-class="user-popover-box"
        trigger="hover"
      >
        <UserPopover />
        <div class="logo" slot="reference" @mouseover="mouseoverUserPopover">
          {{ system.admin && system.admin.userName.slice(0, 1) }}
        </div>
      </el-popover>
    </div>
    <ul class="sidebar-menu">
      <li :key="node.path" v-for="node in displayMenu">
        <div
          :class="[node.path === currentRoute ? 'active' : '', 'menu-node']"
          @click="openRoute(node)"
        >
          <el-badge
            type="danger"
            :max="99"
            :value="node.badge"
            :hidden="node.badge === 0"
          >
            <img :src="node.icon" alt="" />
          </el-badge>
          <div class="menu-name">{{ node.name }}</div>
        </div>
      </li>
      <!--      <li v-if="hasMore && hasMore.name && hasMore.icon">-->
      <!--        <el-popover-->
      <!--          placement="right"-->
      <!--          popper-class="menu-more-popper"-->
      <!--          trigger="hover"-->
      <!--        >-->
      <!--          <ul class="more-menu">-->
      <!--            <li-->
      <!--              @click="openRoute(node)"-->
      <!--              :key="node.path"-->
      <!--              v-for="node in moreMenu"-->
      <!--            >-->
      <!--              <el-badge-->
      <!--                :max="99"-->
      <!--                :value="node.badge"-->
      <!--                :hidden="node.badge === 0"-->
      <!--              >-->
      <!--                <img :src="node.icon" alt="" />-->
      <!--              </el-badge>-->
      <!--              <div class="menu-name">{{ node.name }}</div>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--          <div class="menu-node" slot="reference">-->
      <!--            <img :src="hasMore.icon" alt="" />-->
      <!--            <div class="menu-name">{{ hasMore.name }}</div>-->
      <!--          </div>-->
      <!--        </el-popover>-->
      <!--      </li>-->
    </ul>
    <div class="sidebar-bottom">
      <el-popover placement="right-end" trigger="hover" content="帮助中心">
        <a
          slot="reference"
          target="_blank"
          href="https://www.yuque.com/dhidg6/idbopw"
          ><img src="./question.png" style="width: 18px;" alt=""
        /></a>
      </el-popover>
      <el-popover
        placement="right-end"
        width="200"
        trigger="hover"
        :content="system.org && system.org.orgName"
      >
        <img
          src="../login/imgs/logo.png"
          slot="reference"
          class="platformLogo"
          alt=""
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UserPopover from "@/components/UserPopover.vue";

export default {
  name: "Sidebar",
  data() {
    return {};
  },
  methods: {
    mouseoverUserPopover() {
      this.$DataGather(
        "TMPZDH-001",
        "个人信息弹层",
        "TMPZDH-001-003",
        "个人信息弹层展示数量的PV、UV"
      );
    },
    openRoute(node) {
      this.$DataGather(
        "TMPZDH-001",
        "主导航-一级左侧导航",
        "TMPZDH-001-001",
        "点击一级左侧导航栏-菜单的PV、UV",
        { menuName: node.name }
      );
      if (!node.path) {
        this.$message.info("正在开发,敬请期待");
        return;
      }
      if (node.path === "/client") {
        // 打开方式 新标签
        window.open("./#/client");
        return;
      }
      if (node.method && node.method === "windowOpen") {
        // 新标签打开外网链接
        window.open(node.path);
        return;
      }
      this.$router.push(node.path);
    }
  },
  components: { UserPopover },
  computed: {
    ...mapGetters(["displayMenu", "hasMore", "moreMenu"]),
    ...mapState(["system", "routers"]),
    currentRoute() {
      let routeArr = this.$route.matched;
      return routeArr.length >= 1 ? routeArr[1].path : "";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables.scss";

.sidebar-content {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;

  .sidebar-top {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 34px;
      height: 34px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background: #cdd080;
      border: 3px solid #e8ebef;
      font-size: 16px;

      line-height: 38px;
      color: #fff;
      border-radius: 50%;
    }
  }

  .sidebar-menu {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    li {
      list-style-type: none;
      width: 68px;
      height: 60px;
      margin-bottom: 20px;

      .menu-node {
        width: 68px;
        height: 60px;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: background ease-in;
        color: #818691;

        &:hover,
        &.active {
          background-color: #f6f7fa;
          color: #0084ea;
        }

        img {
          width: 24px;
        }

        .menu-name {
          margin-top: 4px;
          font-size: 14px;
        }
      }
    }
  }

  .sidebar-bottom {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;

    .platformLogo {
      display: block;
      width: 29px;
      border-radius: 5px;
      margin-top: 30px;
    }
  }
}
</style>

<style lang="scss">
/*.el-badge__content {*/
/*  background: linear-gradient(180deg, #3377ff, #4cb2ff);*/
/*}*/
.menu-more-popper {
  border-radius: 12px;
  padding: 20px 36px;

  .more-menu {
    display: flex;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      width: 96px;
      height: 100px;
      margin-right: 32px;
      transition: background ease-in;
      border-radius: 10px;
      cursor: pointer;
      color: #818691;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: #f6f7fa;
        color: #0084ea;
      }

      .menu-name {
        margin-top: 4px;
        font-size: 14px;
      }
    }
  }
}
</style>
