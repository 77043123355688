import { getKeyMap } from "@/api/common/keyMap";
import { fillerLeft } from "@/utils/common";

export function setKeyMap(obj, keys = []) {
  if (keys.length === 0 && Object.keys(obj).length !== 0) {
    getKeyMap(Object.keys(obj))
      .then(r => {
        fillerLeft(obj, r);
      })
      .catch(() => {});
  } else {
    return getKeyMap(keys);
  }
}
