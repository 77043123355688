import Empty from "@/views/layout/Empty.vue";

export default [
  {
    path: "student",
    name: "Student",
    meta: {
      title: "学员"
    },
    component: Empty,
    redirect: "/student/student_department",
    children: [
      {
        path: "student_department",
        name: "StudentDepartment",
        meta: {
          title: "学员与科室"
        },
        component: resolve =>
          require(["@/views/student/studentDepartment/Main.vue"], resolve)
      },
      {
        path: "student_details",
        name: "StudentDetails",
        meta: {
          title: "学员详情",
          parentName: "StudentDepartment"
        },
        component: resolve =>
          require(["@/views/student/studentDetails/Main.vue"], resolve)
      },
      {
        path: "student_import_export",
        name: "StudentImportExport",
        meta: {
          title: "批量导入或导出",
          parentName: "StudentDepartment"
        },
        component: resolve =>
          require(["@/views/student/importExport/Main.vue"], resolve)
      },
      {
        path: "student_invite",
        name: "StudentInvite",
        meta: {
          title: "邀请学员加入",
          parentName: "StudentDepartment"
        },
        component: resolve =>
          require(["@/views/student/inviteStudent/Main.vue"], resolve)
      },
      {
        path: "student_review",
        name: "StudentReview",
        meta: {
          title: "申请人列表",
          parentName: "StudentDepartment"
        },
        component: resolve =>
          require(["@/views/student/studentReview/Main.vue"], resolve)
      },
      {
        path: "manage_admin",
        name: "ManageAdmin",
        meta: {
          title: "管理管理员"
        },
        component: resolve =>
          require(["@/views/student/manageAdmin/Main.vue"], resolve)
      },
      {
        path: "admin_import_export",
        name: "AdminImportExport",
        meta: {
          title: "批量导入管理员",
          parentName: "ManageAdmin"
        },
        component: resolve =>
          require(["@/views/student/importAdmin/Main.vue"], resolve)
      },
      {
        path: "manage_tag",
        name: "manageTag",
        meta: {
          title: "管理标签"
        },
        component: resolve =>
          require(["@/views/student/manageTag/Main.vue"], resolve)
      }
    ]
  }
];
