import Storage from "good-storage";
import Config from "@/config/app";
/**
 * 获取地址栏的参数
 * @param key
 * @param defaultVal
 */
export function currentUrlToParams(key = null) {
  let paramsUrl = window.location.href.split("?");
  if (paramsUrl.length < 2) return key ? null : {};
  let paramsArr = paramsUrl[1].split("&");
  let paramsData = {};
  paramsArr.forEach(r => {
    let data = r.split("=");
    paramsData[data[0]] = data[1];
  });
  if (key) return paramsData.hasOwnProperty(key) ? paramsData[key] : null;
  return paramsData;
}
/**
 * 重置对象（会修改原始对象）
 * @param object
 * @param defaultVal
 */
export function resetObject(object, defaultVal = {}) {
  for (let k in object) {
    if (defaultVal.hasOwnProperty(k)) {
      object[k] = defaultVal[k];
    } else {
      if (Array.isArray(object[k])) object[k] = [];
      if ("string" == typeof object[k]) object[k] = "";
      if ("number" == typeof object[k]) object[k] = null;
      if ("boolean" == typeof object[k]) object[k] = false;
    }
  }
}

/**
 * 对象赋值（会修改原始对象）
 * @param object
 * @param valObject
 */
export function fillerLeft(object, valObject = {}) {
  for (let k in object) {
    if (valObject.hasOwnProperty(k)) {
      object[k] = valObject[k];
    }
  }
}

/**
 * 请求的headers信息
 * @param key
 * @returns {null|*|undefined|{}}
 */
export function getHeaders() {
  return {
    businessSource: 3,
    terminalPlatform: 12,
    requestId: new Date().getTime(),
    Authorization: getToken() || ""
  };
}

/**
 * 获取用户信息
 * @param key
 * @returns {null|*|undefined|{}}
 */
export function getUserInfo(key = null) {
  let userInfo = Storage.get(Config.userInfoKey);
  if (!userInfo) return {};
  if (key) return userInfo.hasOwnProperty(key) ? userInfo[key] : null;
  return userInfo || {};
}

export function getOrgInfo(key = null) {
  let org = Storage.get("TMP_ORG_INFO");
  if (!org) return {};
  if (key) return org.hasOwnProperty(key) ? org[key] : null;
  return org || {};
}
export function getAdminInfo(key = null) {
  let admin = Storage.get("TMP_ADMIN_INFO");
  if (!admin) return {};
  if (key) return admin.hasOwnProperty(key) ? admin[key] : null;
  return admin || {};
}

/**
 * 设置用户信息
 * @param user
 * @returns {*}
 */
export function setUserInfo(user) {
  Storage.set(Config.userInfoKey, user);
  return user;
}
/**
 * 删除用户信息
 * @param user
 * @returns {*}
 */
export function removeUserInfo() {
  Storage.remove(Config.userInfoKey);
}
/**
 * 设置权限
 * @param permissionsArr
 * @returns {*}
 */
export function setPermissions(permissionsArr) {
  Storage.set(Config.permissionsKey, permissionsArr);
  return permissionsArr;
}

/**
 * 获取权限
 * @returns {*|undefined|Array}
 */
export function getPermissions() {
  return Storage.get(Config.permissionsKey) || [];
}

// /**
//  * 判断是否有权限
//  * @param permissionsKey
//  * @returns {boolean}
//  */
// export function hasPermissions(permissionsKey) {
//   let permissionsArr = Storage.get(Config.permissionsKey, []);
//   return permissionsArr.indexOf(permissionsKey) !== -1;
// }

/**
 * 获取Token
 * @returns {*|undefined}
 */
export function getToken() {
  return Storage.get(Config.tokenKey);
}

/**
 * 设置Token
 * @param token
 * @returns {*|undefined}
 */
export function setToken(token) {
  return Storage.set(Config.tokenKey, token);
}

/**
 * 移除Token
 * @returns {*}
 */
export function removeToken() {
  return Storage.remove(Config.tokenKey);
}
/**
 * 存储当前页面的查询条件
 * @returns {*}
 */
export function retainPaginationParams(routeName, params = {}) {
  if (params === {}) return;
  sessionStorage.setItem(routeName + "Params", JSON.stringify(params));
}
/**
 * 取当前页面的查询条件
 * @returns {*}
 */
export function obtainPaginationParams(routeName, searchParams = {}) {
  if (!routeName) return;
  let sessionParams = sessionStorage.getItem(routeName + "Params")
    ? JSON.parse(sessionStorage.getItem(routeName + "Params"))
    : null;
  sessionStorage.removeItem(routeName + "Params");
  let params = sessionParams ? sessionParams : searchParams;
  return params;
}

/**
 * @return {string}
 */
export function getCurrentTime() {
  let t = new Date();
  return `${t.getFullYear()}-${t.getMonth() +
    1}-${t.getDate()} ${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}`;
}

/**
 * 格式化日期 2020-01-01
 * @return {string}
 */
export function getFormatDate() {
  let t = new Date();
  return `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`;
}

/**
 * 判断是否有权限
 * @return {boolean}
 */
export function hasPermissions(permissionsKey) {
  let permissionsArr = Storage.get(Config.permissionsKey, []);

  if (permissionsArr.indexOf("*") != -1) {
    return true;
  }

  return permissionsArr.indexOf(permissionsKey) != -1;
}
/**
 * 处理树形结构选中数据
 * @return {boolean}
 */
export function processingTreeData(checkedNodes = []) {
  if (checkedNodes.length === 0) return [];
  let deptPathList = checkedNodes.map(item => {
    return item.deptPath;
  });
  let pathIdList = [];
  deptPathList.map(item => {
    if (item.indexOf("^") !== -1) {
      let idListSplit = item.split("^");
      idListSplit.splice(idListSplit.length - 1, 1);
      pathIdList.push(...idListSplit);
    }
  });
  let setArray = Array.from(new Set(pathIdList));
  let deptIdList = checkedNodes.filter(item => {
    return setArray.indexOf(item.id) === -1;
  });
  return deptIdList;
}

/**
 * 保留小数，不进行四舍五入的
 * @return {boolean}
 */
export function toFixed(num, decimal) {
  num = num.toString();
  let index = num.indexOf(".");
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1);
  } else {
    num = num.substring(0);
  }
  return parseFloat(num);
}
