import request from "@/utils/request.js";
import { teachingPlatformApi, gatewayPrefix } from "@/config/deploy.js";
/**
 * 登录
 * @param params
 * @returns {AxiosPromise}
 */
export function adminUserLogin(params) {
  return request({
    url: teachingPlatformApi + "/adminUser/adminUserLogin",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}
/**
 * 退出登录
 * @param params
 * @returns {AxiosPromise}
 */
export function loginOut(params) {
  return request({
    url: teachingPlatformApi + "/adminUser/loginOut",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}
/**
 * 省市区区域接口
 * @param params
 * @returns {AxiosPromise}
 */
export function listDistricts(params) {
  return request({
    url: gatewayPrefix + "/dictionary/listDistricts",
    method: "post",
    data: params
  });
}
/**
 * 验证码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function adminUserLoginNote(params) {
  return request({
    url: teachingPlatformApi + "/adminUser/adminUserLoginNote",
    method: "post",
    data: params
  });
}
/**
 * 重置密码
 * @param params
 * @returns {AxiosPromise}
 */
export function adminUserForgotPassword(params) {
  return request({
    url: teachingPlatformApi + "/adminUser/adminUserForgotPassword",
    method: "post",
    data: params
  });
}

/**
 * 机构用户登录
 * @param params
 * @returns {AxiosPromise}
 */
export function currentAdminInfo(params) {
  return request({
    url: teachingPlatformApi + "/adminUser/currentAdminInfo",
    method: "post",
    data: params
  });
}
