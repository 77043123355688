import { setToken, setUserInfo, getUserInfo } from "@/utils/common.js";
import storage from "good-storage";
import { getOrgSonDetails } from "@/api/institutions/secondary.js";
import { currentAdminInfo } from "@/api/user/user.js";

export const system = {
  state: {
    user: getUserInfo(),
    org: storage.get("TMP_ORG_INFO"),
    admin: storage.get("TMP_ADMIN_INFO")
  },
  getters: {
    isGPAdmin: state => {
      //是规培管理员
      return state.user.isCqManager === 1;
    },
    isSuperAdmin: state => {
      //是超级管理员
      return state.user.adminLevel === 0;
    }
  },
  mutations: {
    SER_USER_INFO(state, r) {
      state.user = r;
      setUserInfo(r);
      setToken(r.token);
    },
    SER_ORG_INFO(state, r) {
      state.org = r;
      storage.set("TMP_ORG_INFO", r);
    },
    SER_ADMIN_INFO(state, r) {
      state.admin = r;
      storage.set("TMP_ADMIN_INFO", r);
    }
  },
  actions: {
    async updateOrgInfo(context, orgId) {
      const org = await getOrgSonDetails({ orgId });
      context.commit("SER_ORG_INFO", org);
      context.commit("UPDATE_STUDENT_BADGE", org.orgAplUserCount || 0);

      const admin = await currentAdminInfo({});
      context.commit("SER_ADMIN_INFO", admin);
    }
  }
};
