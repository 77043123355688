import Empty from "@/views/layout/Empty.vue";

export default [
  {
    path: "exam",
    name: "Exam",
    meta: {
      title: "考试"
    },
    component: Empty,
    redirect: "/exam/question",
    children: [
      {
        path: "question",
        name: "Question",
        meta: {
          title: "题库"
        },
        component: resolve =>
          require(["@/views/exam/question/Main.vue"], resolve)
      },
      {
        path: "question_create",
        name: "QuestionCreate",
        meta: {
          title: "创建试题",
          parentName: "Question"
        },
        component: resolve =>
          require(["@/views/exam/questionCreate/QuestionCreate.vue"], resolve)
      },
      {
        path: "question_batch_import",
        name: "QuestionBatchImport",
        meta: {
          title: "批量导入试题",
          parentName: "Question"
        },
        component: resolve =>
          require([
            "@/views/exam/questionBatchImport/QuestionBatchImport.vue"
          ], resolve)
      },
      {
        path: "test_paper",
        name: "TestPaper",
        meta: {
          title: "试卷"
        },
        component: resolve =>
          require(["@/views/exam/testPaper/Main.vue"], resolve)
      },
      {
        path: "test_paper_create",
        name: "TestPaperCreate",
        meta: {
          title: "创建试卷",
          parentName: "TestPaper"
        },
        component: resolve =>
          require(["@/views/exam/testPaperCreate/TestPaperCreate.vue"], resolve)
      },
      {
        path: "test_paper_preview",
        name: "TestPaperPreview",
        meta: {
          title: "预览试卷",
          parentName: "TestPaper"
        },
        component: resolve =>
          require([
            "@/views/exam/testPaperPreview/TestPaperPreview.vue"
          ], resolve)
      },
      {
        path: "exam_list",
        name: "ExamList",
        meta: {
          title: "考试"
        },
        component: resolve => require(["@/views/exam/exam/Main.vue"], resolve)
      },
      {
        path: "exam_create",
        name: "ExamCreate",
        meta: {
          title: "创建考试",
          parentName: "ExamList"
        },
        component: resolve =>
          require(["@/views/exam/examCreate/ExamCreate.vue"], resolve)
      },
      {
        path: "exam_records",
        name: "ExamRecords",
        meta: {
          title: "考试记录",
          parentName: "ExamList"
        },
        component: resolve =>
          require(["@/views/exam/examRecords/ExamRecords.vue"], resolve)
      },
      {
        path: "exam_paper_details",
        name: "ExamPaperDetails",
        meta: {
          title: "答卷详情",
          parentName: "ExamRecords"
        },
        component: resolve =>
          require([
            "@/views/exam/examPaperDetails/ExamPaperDetails.vue"
          ], resolve)
      }
    ]
  }
];
