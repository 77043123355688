export default {
  assign: [
    { name: "卡批次-已分配", code: "1" },
    { name: "卡批次-未分配", code: "2" },
    { name: "课程体系-已分配", code: "3" },
    { name: "课程体系-未分配", code: "4" }
  ],
  assignType: [
    { name: "护士自主选择", code: "1" },
    { name: "医院指定", code: "2" },
    { name: "医院指定部分和护士自主选择部分", code: "3" }
  ],
  progress: [
    { name: "已完成", code: "1" },
    { name: "未完成", code: "0" }
  ],
  cardState: [
    { name: "失效", code: "0" },
    { name: "有效", code: "1" }
  ],
  cardBatch: [
    { name: "课程体系", code: "1" },
    { name: "卡批次", code: "2" }
  ],
  userState: [
    { name: "全部", code: null },
    { name: "未激活", code: "0" },
    { name: "正常", code: "1" }
  ],
  reviewState: [
    { name: "全部", code: "0" },
    { name: "待审批", code: "1" },
    { name: "已通过", code: "2" },
    { name: "已拒绝", code: "3" }
  ],
  courseState: [
    { name: "已完成", code: "0" },
    { name: "未完成", code: "1" }
  ],
  timeSortWay: [
    { name: "按兑换时间最近", code: "0" },
    { name: "按兑换时间最远", code: "1" },
    { name: "按失效时间最近", code: "2" },
    { name: "按失效时间最远", code: "3" }
  ],
  // 题库-难易度选择框，包含：全部、低、中、高
  quality: [
    { name: "全部", code: null },
    { name: "低", code: "1" },
    { name: "中", code: "2" },
    { name: "高", code: "3" }
  ],
  // 题库-试题类型，包含：全部、单选题、多选题
  questionType: [
    { name: "全部", code: null },
    { name: "单选题", code: "1" },
    { name: "多选题", code: "2" },
    { name: "不定项", code: "3" },
    { name: "判断题", code: "4" }
  ],
  // 考试-考试状态，包含：全部、进行中、未开始、已结束、已撤销，
  testingStatus: [
    { name: "全部", code: null },
    { name: "进行中", code: "2" },
    { name: "未开始", code: "1" },
    { name: "已结束", code: "3" },
    { name: "已撤销", code: "4" }
  ],
  // 考试-考试类型，包含：全部、全院学员考试、指定学员考试
  testingType: [
    { name: "全部", code: null },
    { name: "全院学员参加", code: "1" },
    { name: "指定学员考试", code: "2" },
    { name: "指定科室参加", code: "3" }
  ],
  // 考试-试卷来源，包含：赠送试卷、本院试卷
  testPaperSource: [
    { name: "赠送试卷", code: "1" },
    { name: "本院试卷", code: "2" }
  ],
  // 考试-考试结果，包含：全部、未考试、已考试，
  hashTesting: [
    { name: "全部", code: null },
    { name: "已考试", code: "1" },
    { name: "未考试", code: "0" }
  ],
  //是否及格，包含：全部、及格、未及格
  pressFlag: [
    { name: "全部", code: null },
    { name: "未及格", code: "0" },
    { name: "及格", code: "1" }
  ]
};
