<template>
  <div class="user-popover">
    <div class="top">
      <div class="tel">
        当前身份：{{ system.admin.userName }} （{{
          system.admin.userTel.replace(/^(\d{3})\d{6}(\d{2})$/, "$1******$2")
        }}）
      </div>
      <div class="btn-group">
        <div
          class="tip-btn"
          :key="role.id"
          v-for="role in system.admin.roleList"
        >
          {{ role.roleName }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="item" @click="infoSet()">
        医院信息设置
      </div>
      <div class="item" @click="logout()">退出登录</div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { loginOut } from "@/api/user/user.js";
import { removeToken } from "@/utils/common.js";
export default {
  name: "UserPopover",
  // data() {
  //   return {};
  // },
  computed: mapState(["system"]),
  methods: {
    infoSet() {
      this.$DataGather(
        "TMPZDH-001",
        "个人信息弹层",
        "TMPZDH-001-004",
        "点击“医院信息设置”的PV、UV"
      );
      this.$router.push("/org_base_info");
    },
    //退出登录
    logout() {
      this.$DataGather(
        "TMPZDH-001",
        "个人信息弹层",
        "TMPZDH-001-005",
        "点击“退出登录”的PV、UV"
      );
      loginOut().then(() => {
        this.$router.push("/login");
        removeToken();
      });
    }
  }
};
</script>
<style lang="scss">
.user-popover {
  min-width: 280px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/
  .top {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: column;
    padding: 30px 22px;
    .tel {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .btn-group {
      margin-top: 14px;
      display: flex;
      .tip-btn {
        padding: 0 8px;
        height: 24px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background: #1a1a1a;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #fff4d8;
        margin-right: 14px;
      }
    }
  }
  .bottom {
    margin-top: 6px;
    .item {
      padding: 0 22px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 52px;
      border-top: 1px solid #f0f0f0;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      color: #333333;
    }
  }
}
</style>
