import SlsWebLogger from "js-sls-logger";
import { isReleaseEnv } from "@/config/apiUrl.js";
import Config from "@/config/app.js";
import { getUserInfo, getOrgInfo, getAdminInfo } from "@/utils/common.js";

const __topic__ = Config.repoName;
const project = isReleaseEnv ? "jkhh-log-web" : "jkhh-log-test";
const logstore = isReleaseEnv ? "jkhh_admin_log" : "jkhh-test-log";
/* eslint-disable no-undef */
// 获取项目打包时间
const buildTime = BUILD_TIME;

const opts = {
  host: "cn-beijing.log.aliyuncs.com",
  project: project,
  logstore: logstore,
  time: 10,
  count: 10
};
const logger = new SlsWebLogger(opts);

export const dataGather = (
  pageNo,
  pageName,
  eventNo,
  eventName,
  properties = {},
  version = buildTime
) => {
  if (!isReleaseEnv) {
    if (!pageNo) {
      console.warn("埋点页面编号不能为空");
      return;
    }
    if (!pageName) {
      console.warn("埋点页面名字不能为空");
      return;
    }
    if (!eventNo) {
      console.warn("埋点事件编号不能为空");
      return;
    }
    if (!eventName) {
      console.warn("埋点事件名字不能为空");
      return;
    }
  }
  let myProp = {
    hospitalName: getOrgInfo("orgName"),
    hospitalId: getOrgInfo("id"),
    administratorsName: getAdminInfo("userName")
  };
  logger.send({
    __topic__,
    APIVersion: "0.6.0",
    userId: getUserInfo("id") || "0",
    eventTime: new Date().getTime(),
    userAgent: window.navigator.userAgent,
    extra: window.location.href,
    screenHeight: document.documentElement.clientHeight,
    screenWidth: document.documentElement.clientWidth,
    pageNo,
    pageName,
    eventNo,
    eventName,
    properties: { ...myProp, ...properties },
    version
  });
};
