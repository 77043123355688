import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/App.vue";
import Other from "./other.js";
import Common from "./common.js";
import Course from "./course.js";
import Student from "./student.js";
import Exam from "./exam.js";
import Data from "./data.js";
import GuiPei from "./guiPei.js";
import PageManage from "./pageManage.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    name: "Dashboard",
    redirect: "/home",
    meta: {
      title: "仪表盘"
    },
    children: [
      ...Other,
      ...Course,
      ...Student,
      ...Exam,
      ...GuiPei,
      ...Data,
      ...PageManage
    ]
  },
  ...Common
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  routes
});

export default router;
