import request from "@/utils/request.js";
import { teachingPlatformApi } from "@/config/deploy.js";
/**
 * 根据父级获取子级运营标签
 * @param params
 * @returns {AxiosPromise}
 */
export function listByParentId(params) {
  return request({
    url: "/operateService/api/operateTag/listByParentId",
    method: "post",
    data: params,
    closeLoading: true
  });
}

/**
 * 选择机构
 * @param params
 * @returns {AxiosPromise}
 */
export function getOrgTreeList(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/getOrgTreeList",
    method: "post",
    data: params,
    closeLoading: true
  });
}
/**
 * 获取科室字典
 * @param params
 * @returns {AxiosPromise}
 */
export function getUserGroupDic(params) {
  return request({
    url: teachingPlatformApi + "/userGroup/getUserGroupDic",
    method: "post",
    data: params,
    closeLoading: true
  });
}

/**
 * 获取标签字典
 * @param params
 * @returns {AxiosPromise}
 */
export function getUserTagDic(params) {
  return request({
    url: teachingPlatformApi + "/userGroup/getUserTagDic",
    method: "post",
    data: params,
    closeLoading: true
  });
}
