import { menu as m, more } from "@/menu/index.js";
import { cloneDeep } from "lodash";

const displayQuantity = 6;
export const menu = {
  state: {
    menu: m,
    more: more
  },
  mutations: {
    UPDATE_STUDENT_BADGE(state, badge = 0) {
      if (badge === 0) return;
      for (let i = 0; i < state.menu.length; i++) {
        if (state.menu[i].path === "/student") {
          state.menu[i].badge = badge;
          return;
        }
      }
    }
  },
  getters: {
    displayMenu: (state, getters) => {
      if (getters.isGPAdmin) {
        return [state.menu[5]];
      }

      let menu =
        state.menu.length > displayQuantity
          ? state.menu.slice(0, displayQuantity)
          : state.menu;

      for (let i = 0; i < menu.length; i++) {
        if (menu[i].name == "学员" && getters.isSuperAdmin) {
          menu[i].children = [
            {
              name: "学员与科室",
              path: "/student/student_department",
              children: []
            },
            {
              name: "管理员管理",
              path: "/student/manage_admin",
              children: []
            },
            {
              name: "标签管理",
              path: "/student/manage_tag",
              children: []
            }
          ];
          break;
        }
        if (menu[i].name == "学员" && !getters.isSuperAdmin) {
          menu[i].children = [
            {
              name: "学员与科室",
              path: "/student/student_department",
              children: []
            }
          ];
          break;
        }
      }

      return menu;
    },
    hasMore: state => {
      if (state.menu.length > displayQuantity) {
        return state.more;
      }
      return null;
    },
    moreMenu: state => {
      let len = state.menu.length;
      if (len > displayQuantity) {
        return state.menu.slice(displayQuantity, len);
      }
      return [];
    }
  }
};
