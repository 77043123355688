import storage from "good-storage";

export const exam = {
  state: {
    examRecord: storage.get("TMP_EXAM_RECORD"),
    examPaperDetails: storage.get("TMP_EXAM_PAPER_DETAILS"),
    examQuestionR: storage.get("TMP_EXAM_QUESTION_R") || {}, // 新建/编辑试卷-单选
    examQuestionC: storage.get("TMP_EXAM_QUESTION_C") || {}, // 新建/编辑试卷-多选
    examQuestionU: storage.get("TMP_EXAM_QUESTION_U") || {}, // 新建/编辑试卷-不定项
    examQuestionJ: storage.get("TMP_EXAM_QUESTION_J") || {} // 新建/编辑试卷-判断
  },
  mutations: {
    SER_EXAM_RECORD(state, r) {
      state.examRecord = r;
      storage.set("TMP_EXAM_RECORD", r);
    },
    SER_EXAM_PAPER_DETAILS(state, r) {
      state.examPaperDetails = r;
      storage.set("TMP_EXAM_PAPER_DETAILS", r);
    },
    SET_EXAM_QUESTION_R(state, r) {
      state.examQuestionR = r;
      storage.set("TMP_EXAM_QUESTION_R", r);
    },
    SET_EXAM_QUESTION_C(state, r) {
      state.examQuestionC = r;
      storage.set("TMP_EXAM_QUESTION_C", r);
    },
    SET_EXAM_QUESTION_U(state, r) {
      state.examQuestionU = r;
      storage.set("TMP_EXAM_QUESTION_U", r);
    },
    SET_EXAM_QUESTION_J(state, r) {
      state.examQuestionJ = r;
      storage.set("TMP_EXAM_QUESTION_J", r);
    }
  }
};
