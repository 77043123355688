<template>
  <div class="container">
    <Breadcrumb />
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "Container",
  props: {},
  components: { Breadcrumb }
};
</script>
<style lang="scss">
.container {
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  overflow: hidden;
  padding: 0 14px 14px 14px;
  display: flex;
  border: 1px solid #f2f2f2;
  flex-direction: column;
  .content {
    flex: 1;
    background: #fff;
    padding: 20px 32px;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar {
      display: none; //容器不出现滚动条，自己在内部控制
    }
  }
}
</style>
