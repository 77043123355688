<template>
  <router-view />
</template>

<script>
export default {
  name: "BodyMain",
  data() {
    return {};
  },
  methods: {},

  components: {}
};
</script>
<style lang="scss">
@import "../../assets/css/variables.scss";
</style>
