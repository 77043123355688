import Empty from "@/views/layout/Empty";

export default [
  {
    path: "page_manage",
    name: "PageManage",
    meta: {
      title: "页面管理"
    },
    redirect: "/page_manage/index",
    component: Empty,
    children: [
      {
        path: "index",
        name: "PageIndex",
        meta: {
          title: "页面管理",
          parentName: "PageManage"
        },
        component: resolve =>
          require(["@/views/pageManage/index/Index.vue"], resolve)
      },
      {
        path: "slideshow_manage",
        name: "SlideshowManage",
        meta: {
          title: "轮播图",
          parentName: "PageIndex"
        },
        component: resolve =>
          require(["@/views/pageManage/slideshowManage/Index.vue"], resolve)
      },
      {
        path: "slideshow_manage",
        meta: {
          title: "轮播图列表",
          keepAlive: true,
          parentName: "PageIndex"
        },
        component: Empty,
        children: [
          {
            path: "slideshow_add",
            name: "SlideshowAdd",
            meta: {
              title: "新建轮播图",
              parentName: "PageIndex"
            },
            component: resolve =>
              require([
                "@/views/pageManage/slideshowManage/slideshowAdd/Index.vue"
              ], resolve)
          },
          {
            path: "slideshow_update",
            name: "SlideshowUpdate",
            meta: {
              title: "编辑轮播图",
              parentName: "PageIndex"
            },
            component: resolve =>
              require([
                "@/views/pageManage/slideshowManage/slideshowAdd/Index.vue"
              ], resolve)
          }
        ]
      },
      {
        path: "plate",
        name: "Plate",
        meta: {
          title: "板块",
          menuActive: {
            Plate: "/page_manage/index"
          }
        },
        component: resolve =>
          require(["@/views/pageManage/plate/Index.vue"], resolve)
      },
      {
        path: "plate",
        meta: {
          title: "板块列表",
          parentName: "Plate"
        },
        component: Empty,
        children: [
          {
            path: "plate_add",
            name: "PlateAdd",
            meta: {
              title: "新增板块",
              parentName: "Plate"
            },
            component: resolve =>
              require(["@/views/pageManage/plate/plateAdd/Index.vue"], resolve)
          },
          {
            path: "plate_update",
            name: "PlateUpdate",
            meta: {
              title: "编辑板块",
              parentName: "Plate"
            },
            component: resolve =>
              require(["@/views/pageManage/plate/plateAdd/Index.vue"], resolve)
          }
        ]
      }
    ]
  }
];
