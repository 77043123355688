import { render, staticRenderFns } from "./BodyMain.vue?vue&type=template&id=3f6d1a75&"
import script from "./BodyMain.vue?vue&type=script&lang=js&"
export * from "./BodyMain.vue?vue&type=script&lang=js&"
import style0 from "./BodyMain.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports