import Vue from "vue";
import Container from "@/components/Container.vue";
import Pagination from "@/components/Pagination.vue";
import ToolBar from "@/components/ToolBar.vue";
import Empty from "@/components/Empty/Empty.vue";
import MoreOperate from "@/components/MoreOperate.vue";
import KeySelect from "@/components/KeySelect.vue";
import SelectDepartment from "@/components/v3SelectSearch/SelectDepartment.vue";
import SelectTag from "@/components/v3SelectSearch/SelectTag.vue";
import SelectTitle from "@/components/v3SelectSearch/SelectTitle.vue";

Vue.component("SelectTitle", SelectTitle);
Vue.component("SelectTag", SelectTag);
Vue.component("SelectDepartment", SelectDepartment);
Vue.component("KeySelect", KeySelect);
Vue.component("MoreOperate", MoreOperate);
Vue.component("Empty", Empty);
Vue.component("ToolBar", ToolBar);
Vue.component("Container", Container);
Vue.component("Pagination", Pagination);
