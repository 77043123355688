import request from "@/utils/request.js";
import { teachingPlatformApi } from "@/config/deploy.js";

/**
 * 机构用户分页列表
 * @param params
 * @returns {AxiosPromise}
 */
export function listPageOrgSon(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/listPageOrgSon",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}

/**
 * 机构用户详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getOrgSonDetails(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/getOrgSonDetails",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}

/**
 * 机构用户添加
 * @param params
 * @returns {AxiosPromise}
 */
export function addSonOrg(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/addSonOrg",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}

/**
 * 机构用户添加
 * @param params
 * @returns {AxiosPromise}
 */
export function modifySonOrg(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/modifySonOrg",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}

/**
 * 启用禁用机构
 * @param params
 * @returns {AxiosPromise}
 */
export function modifyOrgStatus(params) {
  return request({
    url: teachingPlatformApi + "/stOrg/modifyOrgStatus",
    method: "post",
    data: params //注意：如果是get请求请使用 params: params
  });
}
