<template>
  <div :style="outStyle">
    <el-select
      :disabled="disabled"
      :value="value"
      :size="size"
      :clearable="clearable"
      :placeholder="placeholder"
      :collapse-tags="collapse"
      :multiple="multiple"
      @change="changeOperator"
      :style="'width:' + inputWidth"
    >
      <el-option
        v-for="v in optionArr"
        :key="v[valueField]"
        :label="v[labelField]"
        :value="v[valueField]"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getUserTagDic } from "@/api/common/districts";
export default {
  name: "SelectTag",
  computed: mapState(["system"]),
  props: {
    value: [String, Array],
    placeholder: {
      type: String,
      default: "请选择标签"
    },
    outStyle: {
      type: Object,
      default: () => ({
        display: "inline-block",
        marginRight: "6px"
      })
    },
    inputWidth: {
      type: String,
      default: "200px"
    },
    size: {
      type: String,
      default: "medium"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    collapse: {
      type: Boolean,
      default: false
    },
    valueField: {
      type: String,
      default: "code"
    },
    labelField: {
      type: String,
      default: "name"
    }
  },
  data: function() {
    return {
      optionArr: []
    };
  },
  methods: {
    changeOperator(val) {
      this.$emit("input", val);

      this.$emit("change", val);
    }
  },
  created() {
    let { id: orgId } = this.system.org;
    getUserTagDic({ orgId }).then(res => {
      this.optionArr = res;
    });
  }
};
</script>
