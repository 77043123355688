const home = {
  name: "主页",
  path: "/home",
  badge: 0,
  children: [],
  icon: require("./img/home.png")
};
const exam = {
  name: "考试",
  path: "/exam",
  badge: 0,
  children: [
    {
      name: "题库",
      path: "/exam/question",
      children: []
    },
    {
      name: "试卷",
      path: "/exam/test_paper",
      children: []
    },
    {
      name: "考试",
      path: "/exam/exam_list",
      children: []
    }
  ],
  icon: require("./img/exam.png")
};
const course = {
  name: "课程",
  path: "/course",
  badge: 0,
  icon: require("./img/course.png"),
  children: []
};
const student = {
  name: "学员",
  path: "/student",
  badge: 0,
  children: [
    {
      name: "学员与科室",
      path: "/student/student_department",
      children: []
    },
    {
      name: "管理员管理",
      path: "/student/manage_admin",
      children: []
    },
    {
      name: "标签管理",
      path: "/student/manage_tag",
      children: []
    }
  ],
  icon: require("./img/student.png")
};
const data = {
  name: "数据",
  path: "/data",
  badge: 0,
  children: [
    {
      name: "数据概览",
      path: "/data/overview",
      children: []
    }
  ],
  icon: require("./img/data.png")
};

const GP = {
  name: "规培",
  path: "/gui_pei",
  badge: 0,
  children: [
    {
      name: "规培学员",
      path: "/gui_pei/student",
      children: []
    }
  ],
  icon: require("./img/gp.png")
};
const menu = [home, course, exam, student, data, GP];

const more = {
  name: "更多",
  // path: "/more",
  // children: [],
  icon: require("./img/more.png")
};

export { menu, more };
