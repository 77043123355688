import Empty from "@/views/layout/Empty.vue";

export default [
  {
    path: "data",
    name: "Data",
    meta: {
      title: "数据"
    },
    component: Empty,
    redirect: "/data/overview",
    children: [
      {
        path: "overview",
        name: "Overview ",
        meta: {
          title: "数据概览"
        },
        component: resolve =>
          require(["@/views/data/overview/Overview.vue"], resolve)
      }
      // {
      //   path: "question_create",
      //   name: "QuestionCreate",
      //   meta: {
      //     title: "创建试题",
      //     parentName: "Question"
      //   },
      //   component: resolve =>
      //     require(["@/views/exam/questionCreate/QuestionCreate.vue"], resolve)
      // }
    ]
  }
];
