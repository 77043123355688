<template>
  <div class="empty" v-if="sleep">
    <img :src="type[t].img" alt="" />
    <div v-if="showTip">{{ type[t].tip }}</div>
  </div>
</template>
<script>
export default {
  props: {
    t: {
      type: [String, Number],
      default: 1
    },
    showTip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sleep: false,
      type: {
        1: { tip: "暂无数据", img: require("./img/empty.png") },
        2: { tip: "无系统消息", img: require("./img/msg.png") },
        3: { tip: "搜索为空", img: require("./img/search.png") },
        4: { tip: "暂无考试", img: require("./img/exam.png") },
        5: { tip: "暂无课程", img: require("./img/course.png") }
      }
    };
  },
  mounted() {
    setTimeout(() => {
      this.sleep = true;
    }, 1000);
  }
};
</script>
<style lang="scss" scoped>
.empty {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 190px;
    height: auto;
  }
}
</style>
