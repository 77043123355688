import Vue from "vue";
import App from "./App.vue";
import elementUi from "element-ui";
import Config from "@/config/app";
import wl from "wl-vue-select";
import "wl-vue-select/lib/wl-vue-select.css";
import "@/assets/css/style.scss";
import router from "./router";
import store from "./store";
import "@/utils/v-auth.js";
import { setKeyMap } from "@/utils/setKeyMap.js";
import Dictionary from "@/config/dictionary.js";
import { getToken } from "@/utils/common";
import { dataGather } from "@/utils/logTracking.js";
import "./components";

Vue.config.productionTip = false;
Vue.prototype.GlobalCfg = Config;
Vue.prototype.Dictionary = Dictionary;
Vue.prototype.$Map = setKeyMap;
Vue.prototype.$DataGather = dataGather;

Vue.use(wl);
Vue.use(elementUi);

router.beforeEach((to, from, next) => {
  store.commit("BUILD_ROUTES_CHAIN", to);
  window.document.title = to.meta.title
    ? to.meta.title + "-" + Config.siteName
    : Config.siteName;
  if (
    !getToken() &&
    to.path !== "/login" &&
    to.path !== "/forgetPassword" &&
    to.path !== "/channel" &&
    to.path !== "/client"
  ) {
    next({ path: "/login" });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
