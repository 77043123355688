import request from "@/utils/request.js";
import { teachingPlatformApi } from "@/config/deploy.js";

//添加子科室
export function addDept(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/addDept",
    method: "post",
    data: params
  });
}

//给科室添加管理员
export function addDeptAdminUser(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/manager/addDeptAdminUser",
    method: "post",
    data: params
  });
}

//给科室添加成员
export function addDeptMember(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/member/addDeptMember",
    method: "post",
    data: params
  });
}

//删除科室
export function deleteDept(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/deleteDept",
    method: "post",
    data: params
  });
}

//获取管理员列表
export function getOrgAdminUserList(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/manager/getOrgAdminUserList",
    method: "post",
    data: params
  });
}

//获取成员列表
export function getParentDeptUserList(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/member/getParentDeptUserList",
    method: "post",
    data: params
  });
}

//编辑科室
export function updateDept(params) {
  return request({
    closeLoading: true,
    url: teachingPlatformApi + "/saas/dept/updateDept",
    method: "post",
    data: params
  });
}

//查询科室树
export function getDeptTreeList(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/v1/getDeptTreeList",
    method: "post",
    data: params
  });
}

//获取选中的学员
export function getExistDeptUserList(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/member/getExistDeptUserList",
    method: "post",
    data: params,
    closeLoading: true
  });
}

//可否配置输
export function canEditDept(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/checkDataPermission",
    method: "post",
    data: params,
    closeLoading: true
  });
}

//可否配置输
export function getCurrentAdminDeptList(params) {
  return request({
    url: teachingPlatformApi + "/saas/dept/getCurrentAdminDeptList",
    method: "post",
    data: params,
    closeLoading: true
  });
}
