<template>
  <nav class="nav-bar">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="item in routes.routesChain"
        :key="item.path"
        :to="{ path: item.path, query: item.query }"
      >
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </nav>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Breadcrumb",
  data() {
    return {};
  },
  computed: mapState(["routes"])
};
</script>
<style lang="scss" scoped>
@import "../assets/css/variables";
.nav-bar {
  height: 60px;
  width: 100%;
  z-index: 8;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #343c53;
}
</style>
