export default {
  version: "1.20.0602",
  timeout: 60000,
  apiPrefix: "",
  requestRetry: 4,
  requestRetryDelay: 800,
  tokenKey: "TMP_ACCESS_TOKEN",
  userInfoKey: "TMP_USER_INFO",
  permissionsKey: "JXGLPT_PERMISSION_ARRAY",
  pageSizesArr: [30, 60, 90],
  customerServicePhone: 18611444620,
  ossUrl: "https://jkhhtest-pic.oss-cn-beijing.aliyuncs.com"
};
