<template>
  <div
    class="children-menu-box"
    v-if="rootMenu.children && rootMenu.children.length !== 0"
  >
    <div class="children-menu" :style="`width:${collapse ? 180 : 0}px`">
      <div class="children-menu-body">
        <h2>{{ rootMenu.name }}</h2>
        <ul>
          <li
            :class="{ active: r.path === $route.path }"
            :key="r.path"
            v-for="r in rootMenu.children"
            @click="openRoute(r)"
          >
            <div class="menu-title">{{ r.name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="toggle" @click="collapse = !collapse">
      {{ collapse ? "折 叠" : "展 开" }}
    </div>
  </div>
</template>
<script>
import { menu } from "@/menu/index.js";
export default {
  name: "ChildrenMenu",
  computed: {
    rootMenu: function() {
      if (
        !this.$route.matched ||
        !Array.isArray(this.$route.matched) ||
        this.$route.matched.length < 1
      ) {
        return [];
      }
      let pPath = this.$route.matched[1].path;
      for (let i = 0; i < menu.length; i++) {
        if (pPath === menu[i].path && menu[i].children.length !== 0) {
          return menu[i];
        }
      }
      return [];
    }
  },
  data() {
    return {
      collapse: true
    };
  },

  methods: {
    openRoute(r) {
      this.$DataGather(
        "TMPZDH-001",
        "主导航-二级左侧导航",
        "TMPZDH-001-002",
        "点击二级左侧导航栏-菜单的PV、UV",
        { menuName: r.name }
      );
      this.$router.push(r.path);
    }
  }
};
</script>
<style lang="scss">
.children-menu-box {
  position: relative;
  .children-menu {
    box-shadow: 3px 0 2px 0 rgba(231, 231, 231, 0.23);
    z-index: 5;
    position: relative;
    width: 180px;
    overflow: hidden;
    transition: width ease-in 0.14s;
    height: 100%;
    .children-menu-body {
      width: 180px;

      height: 100%;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: #343c53;
        padding: 24px 26px;
      }
      ul {
        /*margin-left: 26px;*/
        li {
          list-style-type: none;
          font-size: 14px;
          font-weight: 400;
          color: #818691;
          cursor: pointer;
          height: 50px;

          padding: 0 26px;

          &.active,
          &:hover {
            background-color: #f6f7fa;
            color: #0084ea;
          }
          .menu-title {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            &:before {
              content: "";
              /*float: left;*/
              position: relative;
              top: -3px;
              margin-right: 6px;
              margin-top: 8px;
              border-radius: 100%;
              display: block;
              width: 6px;
              height: 6px;
              background: #c0c0c0;
            }
          }
        }
      }
    }
  }
  .toggle {
    position: absolute;
    top: calc(100vh / 2 - 12px);
    right: -24px;
    width: 24px;
    height: 60px;
    color: #fff;
    opacity: 0.4;
    z-index: 1000;
    transition: opacity ease-in;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    display: flex;
    writing-mode: vertical-lr; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
    writing-mode: tb-lr; /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: #0084ea;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
