import Empty from "@/views/layout/Empty.vue";

export default [
  {
    path: "gui_pei",
    name: "GuiPei",
    meta: {
      title: "规培"
    },
    component: Empty,
    redirect: "/gui_pei/student",
    children: [
      {
        path: "student",
        name: "GuiPeiStudent ",
        meta: {
          title: "规培学员"
        },
        component: resolve =>
          require(["@/views/guiPei/student/Main.vue"], resolve)
      },
      {
        path: "record_list",
        name: "GuiPeiRecordList",
        meta: {
          title: "学员填写记录",
          parentName: "GuiPeiStudent"
        },
        component: resolve =>
          require(["@/views/guiPei/recordList/Main.vue"], resolve)
      },
      {
        path: "edit_form",
        name: "GuiPeiEditForm",
        meta: {
          title: "编辑学员填写记录",
          parentName: "GuiPeiRecordList"
        },
        component: resolve =>
          require(["@/views/guiPei/editRecord/Index.vue"], resolve)
      },
      {
        path: "summary_form",
        name: "GuiPeiSummaryForm",
        meta: {
          title: "填写记录汇总",
          parentName: "GuiPeiStudent"
        },
        component: resolve =>
          require(["@/views/guiPei/summaryForm/Index.vue"], resolve)
      },
      {
        path: "delete_record",
        name: "GuiPeiDeleteRecord",
        meta: {
          title: "已删除记录",
          parentName: "GuiPeiStudent"
        },
        component: resolve =>
          require(["@/views/guiPei/delRecord/Main.vue"], resolve)
      }
    ]
  },
];
