
export default [
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "后台登录",
      keepAlive: false
    },
    components: {
      blank: resolve => require(["@/views/login/Login.vue"], resolve)
    }
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    meta: {
      title: "修改密码",
      keepAlive: true
    },
    components: {
      blank: resolve => require(["@/views/forgetPassword/Index.vue"], resolve)
    }
  },
  {
    path: "/client",
    name: "Client",
    meta: {
      title: "下载客户端",
      keepAlive: false
    },
    components: {
      blank: resolve => require(["@/views/client/Index.vue"], resolve)
    }
  },
  {
    path: "/channel",
    name: "Channel",
    meta: {
      title: "后台登录",
      keepAlive: false
    },
    components: {
      blank: resolve => require(["@/views/channel/Index.vue"], resolve)
    }
  },
  {
    path: "/gui_pei_pdf_print",
    name: "GuiPeiPdfPrint",
    meta: {
      title: "规培PDF打印",
      keepAlive: false
    },
    components: {
      blank: resolve => require(["@/views/guiPei/PdfPreview.vue"], resolve)
    }
  },
  {
    path: "/gui_pei_summary_pdf_print",
    name: "GuiPeiSummaryPdfPrint",
    meta: {
      title: "规培PDF打印",
      keepAlive: false
    },
    components: {
      blank: resolve => require(["@/views/guiPei/PdfSummaryPreview.vue"], resolve)
    }
  },
];
